import { type UserApi } from '@boommed-suite/contracts'
import { HttpRequestError, isError, NetworkError } from '@boommed-suite/typescript-crossplatform'
import { zodResolver } from '@hookform/resolvers/zod'
import { mdiClose } from '@mdi/js'
import MDIIcon from '@mdi/react'
import { Box, Button, Drawer, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useInjection } from 'inversify-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BoommedService } from '../../../domain/services/BoommedService'
import { Crashlytics } from '../../../domain/services/Crashlytics'
import { useAppNavigate } from '../../app/AppRouter'
import { useAppContext } from '../../app/contexts/AppContext'
import { useNotificationContext } from '../../app/contexts/NotificationContext'
import { theme } from '../../app/Theme'
import { CustomMUIForm } from '../../components/CustomMUIForm/CustomMUIForm'
import { SuiteButton } from '../../components/SuiteButton/SuiteButton'
import { styles } from '../UsersPage/components/NewUserForm/NewUserForm.styles'
import { UserProfileForm, userProfileFormSchema } from './UserProfileForm'

const EditUserProfileHeader = () => {
  const { t } = useTranslation()
  const navigate = useAppNavigate()
  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack direction="row" sx={styles.header}>
      <Typography variant="h4" flexGrow={1} fontWeight="bold">
        {t('profile')}
      </Typography>
      <Box sx={styles.closeButton} onClick={onClose}>
        <MDIIcon path={mdiClose} size={0.75} />
      </Box>
    </Stack>
  )
}

const EditUserProfileFooter = () => {
  const navigate = useAppNavigate()
  const { t } = useTranslation()
  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack
      sx={styles.footerContainer}
      direction="row"
      spacing={theme.spacing(2)}
    >
      <Box sx={styles.footerDiscardContainer}>
        <Button variant="text" onClick={onClose}>
          {t('discard')}
        </Button>
      </Box>

      <SuiteButton variant="contained" type="submit">
        {t('edit_profile_submit')}
      </SuiteButton>
    </Stack>
  )
}
export function UserProfilePage() {  
  const navigate = useAppNavigate()
  const boommedService = useInjection(BoommedService)
  const { menu, setUser } = useAppContext()
  const crashlytics = useInjection(Crashlytics)
  const { action: routeAction } = useParams()
  const { openNotification } = useNotificationContext()

  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }

  const updateProfile = useCallback(
    async (values: UserApi.UpdateUserProfileRequest) => {
      if (menu?.items?.user?._links?.info === undefined) {
        crashlytics.error(new Error('User info link not found'))
        openNotification({
          severity: 'error',
          text: t('user_add_error'),
        })
        return
      }

      const [profile, error] = await boommedService.fetch<UserApi.UserProfileResponse>(menu.items.user._links.profile)
      
      if (isError(Error)(error) || profile?._links?.update === undefined) {
        crashlytics.error(new Error('User profile link not found'))
        openNotification({
          severity: 'error',
          text: t('user_add_error'),
        })
        return
      }
      
      const[_result, updateError] = await boommedService.fetch(profile._links.update, values)
     
      if (isError(NetworkError)(updateError)) {
        setUnexpectedError(updateError)
        return
      }      

      if (isError(HttpRequestError)(updateError)) {
        crashlytics.error(updateError)
        openNotification({
          severity: 'error',
          text: t('user_add_error'),
        })
        return
      }

      const [updatedUser] = await boommedService.fetch<UserApi.UserInfoResponse>(
        menu.items.user._links.info,
      )
      setUser(updatedUser)
      navigate(-1)
      
    },
    [menu],
  )

  return (
    <Drawer
    anchor="right"
    open={routeAction === 'edit'}
    sx={styles.drawer}
    variant="temporary"
    >
      <Box sx={styles.container}>
        <EditUserProfileHeader />
        <CustomMUIForm
            resolver={zodResolver(userProfileFormSchema)}
            onSubmit={updateProfile}
          >
            <UserProfileForm />
            <EditUserProfileFooter />
        </CustomMUIForm>
      </Box>
    </Drawer>
  )
}
