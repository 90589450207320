import { type GradientPaletteOptions, theme } from '../../../app/Theme'

export const styles = {
  root: { margin: 'auto', flexDirection: 'column' },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    bgcolor: theme.palette.secondary.main,
    color: theme.palette.primary.main,
  },
  header: {
    margin: 'auto',
    flexDirection: 'column',
    background: (theme.palette.primary as GradientPaletteOptions).gradient,
  },
  actions: {
    display: 'flex',
    alignContent: 'flex-end',
    justifyContent: 'flex-end',
  },
}
