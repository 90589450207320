import { NavigateNext } from '@mui/icons-material'
import AddIcon from '@mui/icons-material/Add'
import { Box, Card, CardActions, CardContent, CardHeader, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useMatches } from 'react-router-dom'
import { AppRoutes, buildNavigationPath, useAppNavigate } from '../../../app/AppRouter'
import i18next from '../../../app/i18n/i18n'
import { GradientPaletteOptions, theme } from '../../../app/Theme'
import { styles } from './TenantCard.styles'


export const AddTenantCard = () =>{
  const navigate = useAppNavigate()
  const matches = useMatches()
  return (    
    <Card sx={styles.root}  raised>     
      <CardHeader sx={{...styles.header, background: theme.palette.secondary}}
        avatar={
          <Box sx={{...styles.avatar, bgcolor:undefined, display:'grid', alignItems:'end', justifyContent:"center"}}>
            <IconButton 
              onClick = {() => {
                      if (matches[1]?.id === 'tenants' || matches[1]?.id === 'tenants_add') {
                        const link = buildNavigationPath(AppRoutes.tenantAdd, {
                          action: 'add',
                        })
                        navigate(link)
                      }
                    }} 
              sx={{ 
                width: theme.spacing(6),
                height: theme.spacing(6),
                background: (theme.palette.primary as GradientPaletteOptions).gradient,
                color: (theme) => theme.palette.primary.contrastText, '&:hover': {
                  background: (theme) =>
                    (theme.palette.primary as GradientPaletteOptions).gradient,
                  filter: 'brightness(0.9)'
              },
            }}>
              <AddIcon  />
            </IconButton>
         </Box>
        }
      >      
      </CardHeader>
      <CardContent>
        <Typography variant="subtitle1" noWrap>
            {i18next.t('add_clinic')}
        </Typography>
        <CardActions >        
          <IconButton sx={{...styles.actions, visibility:'hidden'}} >
                <NavigateNext />
          </IconButton>
        </CardActions>
      </CardContent>        
    </Card>
  )
}
