import { UserApi } from '@boommed-suite/contracts'
import { Stack } from '@mui/material'
import { useInjection } from 'inversify-react'
import React from 'react'
import { useTranslation } from 'react-i18next'
import z from 'zod'
import { BoommedService } from '../../../domain/services/BoommedService'
import { useAppContext } from '../../app/contexts/AppContext'
import { CustomFormTextField } from '../../components/CustomMUIForm/CustomFormTextField'
import { useService } from '../../hooks/useService'
import { styles } from './UserProfileForm.styles'

export const userProfileFormSchema = z.object({
  name: z.string().optional(),
  vat: z.string().optional(),
  phoneNumber: z.string().optional(),
  address: z.string().optional(),
})
export type UserProfileFormSchema = z.infer<typeof userProfileFormSchema>

export function UserProfileForm() {
  const { t } = useTranslation()
  const { menu } = useAppContext()
  const boommedService = useInjection(BoommedService)

  const { data: profile } = useService<UserApi.UserProfileResponse>(
    {
      service: async () => {
        if (menu?.items?.user?._links?.profile) {
          const [response] = await boommedService.fetch(
            menu.items.user._links.profile,
          )
          return response
        }
        return undefined
      },
    },
    [menu],
  )

  const {    
    name: nameTemplate,
    vat: vatTemplate,
    phoneNumber: phoneNumberTemplate,
    address: addressTemplate
  }: Record<string, UserApi.TemplateField> =
    profile?.templates.update || {}

  return (
    <Stack direction="column" sx={styles.form} spacing={2}>
      {nameTemplate && (
        <CustomFormTextField
          name="name"
          label={t(nameTemplate.label)}
          required={nameTemplate.required}
          defaultValue={profile?.profile.name}
        />
      )}
      {vatTemplate && (
        <CustomFormTextField
          name="vat"
          label={t(vatTemplate.label)}
          required={vatTemplate.required}
          defaultValue={profile?.profile.vat}
        />
      )}
      {phoneNumberTemplate && (
        <CustomFormTextField
          name="phoneNumber"
          label={t(phoneNumberTemplate.label)}
          required={phoneNumberTemplate.required}
          defaultValue={profile?.profile.phoneNumber}
        />
      )}
      {addressTemplate && (
        <CustomFormTextField
          name="address"
          label={t(addressTemplate.label)}
          required={addressTemplate.required}
          defaultValue={profile?.profile.address}
        />
      )}
    </Stack>
  )
}
