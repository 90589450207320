import React from 'react'
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useNavigate,
} from 'react-router-dom'
import { AppHeader } from '../components/AppHeader/AppHeader'
import { AppMenu } from '../components/AppMenu/AppMenu'
import { AppMenuContextProvider } from '../components/AppMenu/AppMenuContext'
import { AppointmentsPage } from '../pages/AppointmentsPage/AppointmentsPage'
import { ClientsPage } from '../pages/ClientsPage/ClientsPage'
import { HospitalizationDetailHeader } from '../pages/Hospitalization/components/HospitalizationDetailHeader/HospitalizationDetailHeader'
import { HospitalizationDetailPage } from '../pages/Hospitalization/HospitalizationDetailPage/HospitalizationDetailPage'
import { TenantPage } from '../pages/TenantPage/TenantPage'
import { TenantsPage } from '../pages/TenantsPage/TenantsPage'
import { UnexpectedErrorPage } from '../pages/UnexpectedErrorPage'
import { UserProfilePage } from '../pages/UserProfilePage/UserProfilePage'
import { UsersPage } from '../pages/UsersPage/UsersPage'
import i18n from './i18n/i18n'

export const AppRoutes = {
  root: '/',
  tenant: '/tenant/:tenantId',
  tenantAdd: '/tenants/:action',
  tenants: '/tenants',
  users: '/tenant/:tenantId/users',
  userAdd: '/tenant/:tenantId/users/:action',
  userProfile: '/me',
  userProfileEdit: '/me/:action',
  clients: '/tenant/:tenantId/clients',
  clientAdd: '/tenant/:tenantId/clients/:action',
  appointments: '/tenant/:tenantId/appointments',
  appointmentAdd: '/tenant/:tenantId/appointment/:action',
  hospitalizationRoot: '/tenant/:tenantId/hospitalization',
  hospitalizationDetail: '/tenant/:tenantId/hospitalization/:hospitalizationId',
}

const router = createBrowserRouter([
  {
    path: AppRoutes.root,
    element: (
      <>
        <AppHeader />
        <AppMenuContextProvider>
          <div
            style={{
              flexGrow: 1,
              overflowY: 'auto',
              marginBottom: '96px',
              paddingTop: '16px',
            }}
          >
            <Outlet />
          </div>
          <AppMenu />
        </AppMenuContextProvider>
      </>
    ),
    errorElement: (
      <UnexpectedErrorPage>{i18n.t('page_not_found')}</UnexpectedErrorPage>
    ),
    children: [
      {
        path: AppRoutes.root,
        element: <Navigate to={AppRoutes.tenants} />,
      },
      {
        id: 'tenants',
        path: AppRoutes.tenants,
        element: <TenantsPage />,
      },
      {
        id: 'tenant',
        path: AppRoutes.tenant,
        element: <TenantPage />,
      },
      {
        id: 'tenants_add',
        path: AppRoutes.tenantAdd,
        element: <TenantsPage />,
      },
      {
        id: 'users',
        path: AppRoutes.users,
        element: <UsersPage />,
      },
      {
        id: 'users_add',
        path: AppRoutes.userAdd,
        element: <UsersPage />,
      },
      {
        id: 'edit_profile',
        path: AppRoutes.userProfileEdit,
        element: <UserProfilePage />,
      },
      {
        id: 'clients',
        path: AppRoutes.clients,
        element: <ClientsPage />,
      },
      {
        id: 'clients_add',
        path: AppRoutes.clientAdd,
        element: <ClientsPage />,
      },
      {
        id: 'appointments',
        path: AppRoutes.appointments,
        element: <AppointmentsPage />,
      },
      {
        id: 'appointments_add',
        path: AppRoutes.appointmentAdd,
        element: <AppointmentsPage />,
      },
    ],
  },
  {
    id: 'hospitalization_root',
    path: AppRoutes.hospitalizationRoot,
    element: (
      <>
        <HospitalizationDetailHeader />
        <Outlet />
      </>
    ),
    children: [
      {
        id: 'hospitalization_detail',
        path: AppRoutes.hospitalizationDetail,
        element: <HospitalizationDetailPage />,
      },
    ],
  },
])

export const AppRouter = () => {
  return <RouterProvider router={router} />
}

export const buildNavigationPath = (
  route: string,
  replacements: Record<string, string>,
) =>
  Object.entries(replacements).reduce(
    (finalRoute, [key, value]) => finalRoute.replace(`:${key}`, value),
    route,
  )

export const useAppNavigate = () => {
  const navigate = useNavigate()

  return (
    route: string | number,
    replacements: Record<string, string> = {},
  ) => {
    if (typeof route === 'number') {
      navigate(route)
    } else {
      const path = buildNavigationPath(route, replacements)
      navigate(path)
    }
  }
}
