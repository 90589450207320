import { type UserApi } from '@boommed-suite/contracts'
import { Grid } from '@mui/material'
import { useInjection } from 'inversify-react'
import React, { useEffect } from 'react'
import { BoommedService } from '../../../domain/services/BoommedService'
import { useAppContext } from '../../app/contexts/AppContext'
import { useService } from '../../hooks/useService'
import { styles } from './TenantsPage.styles'
import { AddTenantCard } from './components/AddTenantCard'
import { AddTenantPage } from './components/NewTenant/AddTenantPage'
import { TenantCard } from './components/TenantCard'

const useDeepLink = () => {
  const boommedService = useInjection(BoommedService)
  const { menu, setMenu } = useAppContext()

  useService<UserApi.ListTenantsResponse>(
    {
      service: async () => {
        if (menu && !menu.items?.tenants && menu._links?.parentSignin) {
          await boommedService.fetch(menu._links.parentSignin)

          const [parentTenantMenu, error] = await boommedService.loadMenu()

          if (error) {
            throw error
          }

          setMenu(parentTenantMenu)
        }
      },
    },
    [menu],
  )
}

export function TenantsPage() {
  const boommedService = useInjection(BoommedService)
  const { menu } = useAppContext()
  
  useDeepLink()

  const { data } = useService<UserApi.ListTenantsResponse>(
    {
      service: async () => {
        if (menu?.items?.tenants?._links?.list) {
          const [result, error] =
            await boommedService.fetch<UserApi.ListTenantsResponse>(
              menu.items.tenants._links.list,
            )

          if (error) {
            throw error
          }

          return result
        }

        return undefined
      },
    },
    [menu],
  )
  useEffect(() => {       
    const handlePopState = () => {window.location.reload()}
    window.addEventListener('popstate', handlePopState)     
    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [])

  return (
    <>
      <Grid
        container
        spacing={{ xs: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={styles.container}
        justifyContent="center"
        alignContent="center"
      >
        {data?.tenants?.map((tenant) => (
          <Grid
            key={tenant.id}
            item
            xs={2}
            sm={4}
            md={4}
            justifyContent="center"
            alignContent="center"
          >
            <TenantCard tenant={tenant} />
          </Grid>
        ))}
        {menu?.items?.tenants?._links?.new? 
          <Grid
          item
          xs={2}
          sm={4}
          md={4}
          justifyContent="center"
          alignContent="center"
          >
          <AddTenantCard />
        </Grid>
        :null
        }
      </Grid>
      <AddTenantPage />
    </>
  )
}
