import { HttpRequestError, isError, NetworkError } from '@boommed-suite/typescript-crossplatform'
import { zodResolver } from '@hookform/resolvers/zod'
import { mdiClose } from '@mdi/js'
import MDIIcon from '@mdi/react'
import { Box, Button, Drawer, Stack, Typography } from '@mui/material'
import { t } from 'i18next'
import { useInjection } from 'inversify-react'
import React, { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { BoommedService } from '../../../../../domain/services/BoommedService'
import { Crashlytics } from '../../../../../domain/services/Crashlytics'
import { useAppNavigate } from '../../../../app/AppRouter'
import { useAppContext } from '../../../../app/contexts/AppContext'
import { useNotificationContext } from '../../../../app/contexts/NotificationContext'
import { theme } from '../../../../app/Theme'
import { CustomMUIForm } from '../../../../components/CustomMUIForm/CustomMUIForm'
import { SuiteButton } from '../../../../components/SuiteButton/SuiteButton'
import { NewTenantForm, NewTenantFormSchema, newTenantFormSchema } from '../NewTenantForm/NewTenantForm'
import { styles } from '../NewTenantForm/NewTenantForm.styles'

interface submitValues {
  name: string,
  externalLink?: string,
  description?: string
}
const NewTenantHeader = () => {
  const { t } = useTranslation()
  const navigate = useAppNavigate()

  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack direction="row" sx={styles.header}>
      <Typography variant="h4" flexGrow={1} fontWeight="bold">
        {t('add_clinic')}
      </Typography>
      <Box sx={styles.closeButton} onClick={onClose}>
        <MDIIcon path={mdiClose} size={0.75} />
      </Box>
    </Stack>
  )
}

const NewTenantFooter = () => {
  const navigate = useAppNavigate()
  const { t } = useTranslation()

  const onClose = () => {
    navigate(-1)
  }

  return (
    <Stack
      sx={styles.footerContainer}
      direction="row"
      spacing={theme.spacing(2)}
    >
      <Box sx={styles.footerDiscardContainer}>
        <Button variant="text" onClick={onClose}>
          {t('discard')}
        </Button>
      </Box>

      <SuiteButton variant="contained" type="submit">
        {t('tenant_submit')}
      </SuiteButton>
    </Stack>
  )
}
export function AddTenantPage() {
  const { action: routeAction } = useParams()
  const { openNotification } = useNotificationContext()
  const crashlytics = useInjection(Crashlytics)
  
  const [unexpectedError, setUnexpectedError] = useState<Error>()
  if (unexpectedError) {
    throw unexpectedError
  }

  const navigate = useAppNavigate()
  const { menu } = useAppContext()
  const boommedService = useInjection(BoommedService)

  const createTenant = useCallback(async (values: submitValues) => {
    const newTenantLink = menu?.items?.tenants?._links?.new

    if (newTenantLink === undefined) {
      crashlytics.error(new Error('New tenant link not found'))
      openNotification({
        severity: 'error',
        text: t('tenant_add_error'),
      })
      return
    }
    
    const [result, error] = await boommedService.fetch(newTenantLink, values)

    if (isError(NetworkError)(error)) {
      setUnexpectedError(error)
      return
    }
    
    if (isError(Error)(error) || isError(HttpRequestError)(error)) {
      crashlytics.error(error)
      openNotification({
        severity: 'error',
        text: t('tenant_add_error'),
      })
      return
    }
    
    if (result){
      navigate(-1)
    }
    
  }, [])

  const onSubmit = (values: NewTenantFormSchema) => {
    void createTenant(values)
  }

  return (
    <Drawer
      anchor="right"
      open={routeAction === 'add'}
      sx={styles.drawer}
      variant="temporary"
    >
      <Box sx={styles.container}>
        <NewTenantHeader />
        <CustomMUIForm
          resolver={zodResolver(newTenantFormSchema)}
          onSubmit={onSubmit}
        >
          <NewTenantForm />
          <NewTenantFooter />
        </CustomMUIForm>
      </Box>
    </Drawer>
  )
}
