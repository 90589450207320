import { UserApi } from '@boommed-suite/contracts'
import { Stack } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import z from 'zod'
import { useAppContext } from '../../../../app/contexts/AppContext'
import i18n from '../../../../app/i18n/i18n'
import { CustomFormTextField } from '../../../../components/CustomMUIForm/CustomFormTextField'
import { styles } from './NewTenantForm.styles'

export const newTenantFormSchema = z.object({
  name: z
    .string()
    .min(1, { message: `${i18n.t('name')} ${i18n.t('is_required')}` }),
  externalLink: z.string().optional(),
  description: z.string().optional(),
})
export type NewTenantFormSchema = z.infer<typeof newTenantFormSchema>

export function NewTenantForm() {
  const { t } = useTranslation()
  const { menu } = useAppContext()

  const {
    name: nameTemplate,
    externalLink: externalLinkTemplate,
    description: descriptionTemplate
  }: Record<string, UserApi.TemplateField> =
  menu?.items?.tenants?.data?.templates?.new || {}
  

  return (
    <Stack direction="column" sx={styles.form} spacing={2}>
      {nameTemplate && (
        <CustomFormTextField
          name="name"
          label={t(nameTemplate.label)}
          required={nameTemplate.required}
        />
      )}
      {externalLinkTemplate && (
        <CustomFormTextField
          name="externalLink"
          label={t(externalLinkTemplate.label)}
          required={externalLinkTemplate.required}
        />
      )}
      {descriptionTemplate && (
        <CustomFormTextField
          name="description"
          label={t(descriptionTemplate.label)}
          required={descriptionTemplate.required}
        />
      )}
    </Stack>
  )
}
